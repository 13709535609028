<template lang="pug">
pre-loader(:show="false")
  product(:isConfirmed="true")
</template>

<script>
  import { mapState, mapMutations, } from 'vuex'

  import Product from './Product'

  export default {
    name: 'Confirmed',
    components: {
      Product
    },
    computed: {
      ...mapState('reseller', {
        resellerInfo: state => state.resellerInfo,
      }),
    },
    mounted() {
      this.setVehicleLoaded(true);

      if (this.$route.query.grandidsession) {
        const orderId = localStorage.getItem('orderInfo');
        
        const url = `orders/${(orderId).toString()}/change-status`;
        const params = {
          status: 1
        };

        // eslint-disable-next-line
        axios.put(url, params, {
          headers: {
            'Authorization': localStorage.getItem('Authorization')
          },
        });
      }
    },
    methods: {
      ...mapMutations('product', ['setVehicleLoaded']),
    },
  }
</script>
